import {
  getEditorHTMLContent,
  getEmptyEditorState,
  getUpdatedEditorState,
} from '@common/components/RichTextEditor/helpers';
import Spinner from '@common/components/Spinner';
import { trackEvent } from '@common/utils/track-helpers';
import { useIsComplianceRequirementsAddOnEnabled } from '@modules/add-on';
import {
  createComplianceProfile,
  fetchComplianceProfile,
  updateComplianceProfile,
} from '@modules/compliance-profile/actions';
import { ComplianceProfileContext } from '@modules/compliance-profile/constants';
import { fetchProjectCustomFields } from '@modules/custom-field/actions';
import { CustomFieldType } from '@modules/custom-field/constants';
import ConnectivityBadge from '@modules/integration/components/ConnectivityBadge';
import { fetchPartyToProjectModal } from '@modules/party/actions';
import { getGraphqlPayload, getGraphqlResponse } from '@store/helpers';
import { Button, Form, Modal, Tabs, Typography, message } from 'antd';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  createProject,
  fetchProjectById,
  openProjectUpdatesWSSubscription,
  updateProject,
} from '../actions';
import CustomFieldsTab from '../components/CustomFieldsTab';
import ProjectRequirementsView from '../components/ProjectRequirementsView';
import ProjectAttachmentsTabContainer from '../containers/ProjectAttachmentsTabContainer';
import ProjectPartiesTabContainer from '../containers/ProjectPartiesTabContainer';
import GeneralTabForm from '../forms/GeneralTabForm';
import { useProjectComplianceProfiles } from '../hooks/useProjectComplianceProfiles';
import { preserveActiveStatus } from '../utils/preserve-active-status';

const { TabPane } = Tabs;

const TabKeys = {
  General: {
    name: 'Overview',
    key: 'general',
  },
  CustomFields: {
    name: 'Custom fields',
    key: 'customFields',
  },
  Parties: {
    name: 'Parties',
    key: 'parties',
  },
  Requirements: {
    name: 'Compliance profiles',
    key: 'requirements',
  },
  ProjectAttachments: {
    name: 'Attachments',
    key: 'projectAttachments',
  },
};

const omittedFields = [
  '_id',
  'complianceProfile',
  'partiesCount',
  'projectsCount',
  'createdAt',
  'updatedAt',
  'deletedAt',
  'deletedBy',
  'sourceId',
  'sourceType',
];

const getUTCMomentDate = (momentDate) => {
  if (!momentDate || !moment.isMoment(momentDate)) {
    return momentDate;
  }
  const plainStartDate = momentDate.format('YYYY-MM-DD');
  return moment.utc(plainStartDate).format();
};

const SaveProjectModal = ({
  projectId,
  initParties = [],
  visible,
  onClose,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isModalDataLoading, setIsModalDataLoading] = useState();
  const [project, setProject] = useState(null);
  const [tab, setTab] = useState(TabKeys.General.key);
  const [parties, setParties] = useState([]);
  const [selectedCustomFields, setSelectedCustomFields] = useState([]);
  // Object where key is the party id and value is the original compliance profile of the project profile associated to the party
  const [partyProfileAssociation, setPartyProfileAssociation] = useState({});
  // profiles got from the fetched project data
  const [profilesMappings, setProfilesMappings] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  // array of all the profile ids, both project and original of the same party
  const [localComplianceProfilesIds, setLocalComplianceProfilesIds] = useState(
    [],
  );

  /*
  Array of all the original profiles extended with:
  - rules from custom fields; if a rule is already in the profile, custom field isActive wins
  - rules from project: all the isActive statuses are taken and replaced to the ones of the original compliance profile
  */
  const [localComplianceProfiles, setLocalComplianceProfiles] = useState([]);
  const [generalTabForm] = Form.useForm();
  const [uploadFiles, setUploadFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);

  const { isComplianceRequirementsAddOnEnabled } =
    useIsComplianceRequirementsAddOnEnabled();

  const dispatch = useDispatch();

  const init = async () => {
    setLoading(true);
    const customFieldsRes = await dispatch(
      fetchProjectCustomFields({ first: 150 }),
    );
    /**
     *  TODO: Refactor this and get it with the selector and evaluate to move the entire logic to the
     *  TODO: CustomFieldsTab component
     */
    setCustomFields(getGraphqlResponse(customFieldsRes)?.nodes);
    setParties(initParties || []);

    if (projectId) {
      const projectRes = await dispatch(fetchProjectById(projectId));
      const projectData = getGraphqlPayload(projectRes);

      setProfilesMappings(R.propOr([], 'complianceProfiles', projectData));
      generalTabForm.setFieldsValue({
        description: getUpdatedEditorState(projectData.description),
      });
      generalTabForm.setFieldsValue({
        dates: [
          projectData.startDate ? moment.utc(projectData.startDate) : null,
          projectData.endDate ? moment.utc(projectData.endDate) : null,
        ],
      });
      setParties(projectData.parties);
      setAttachments(projectData.attachments);
      setSelectedCustomFields(projectData?.customFields?.nodes ?? []);
      setProject(projectData);
    }
    setLoading(false);
  };

  const reset = () => {
    generalTabForm.resetFields();
    generalTabForm.setFieldsValue({
      description: getEmptyEditorState(),
    });
    setLoading(false);
    setSubmitting(false);
    setProject(null);
    setTab(TabKeys.General.key);
    setParties([]);
    setSelectedCustomFields([]);
    setPartyProfileAssociation({});
    setProfilesMappings([]);
    setCustomFields([]);
    setLocalComplianceProfiles([]);
    setLocalComplianceProfilesIds([]);
    setUploadFiles([]);
    setAttachments([]);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    if (visible) {
      init();
    } else {
      reset();
    }
  }, [visible, projectId]);
  // * Keep an updated list of all compliance profiles ids
  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    setLocalComplianceProfilesIds(
      R.compose(
        R.reject((id) => R.isNil(id)),
        R.uniq,
        R.flatten,
        R.concat(
          R.map(
            (party) =>
              R.path(['partyComplianceProfile', 'complianceProfile'], party),
            parties,
          ),
        ),
        R.concat(R.values(partyProfileAssociation)),
        R.map(R.values),
      )(profilesMappings),
    );
  }, [dispatch, partyProfileAssociation, profilesMappings, parties]);

  const { complianceProfiles = [], loading: isFetchingProfiles } =
    useProjectComplianceProfiles(
      {
        globalComplianceProfileIds: localComplianceProfilesIds,
        projectId: projectId || undefined,
        customFields: selectedCustomFields?.map((cf) => {
          return {
            id: cf.customField._id,
            value: cf.value,
          };
        }),
      },
      {
        skip: !localComplianceProfilesIds.length,
        onCompleted: (data) => {
          const projectComplianceProfiles = data.projectComplianceProfiles;
          setLocalComplianceProfiles((prev) => {
            return preserveActiveStatus(prev, projectComplianceProfiles);
          });
        },
      },
    );

  // * Update party profile association when parties change
  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    setPartyProfileAssociation((prev) => {
      return parties.reduce(
        (acc, val) => {
          const profileId = val.partyComplianceProfile?.complianceProfile;

          if (!profileId) return acc;

          if (val._id in prev) return acc;

          const originalProfileId = profilesMappings.find(
            (el) => el.projectComplianceProfile === profileId,
          )?.originalComplianceProfile;

          acc[val._id] = originalProfileId || profileId;

          return acc;
        },
        {
          ...prev,
        },
      );
    });
  }, [parties]);

  const onSelectParty = async (value) => {
    setIsModalDataLoading(true);
    if (!parties.map((party) => party._id).includes(value)) {
      const payload = await dispatch(fetchPartyToProjectModal(value));
      const party = getGraphqlPayload(payload);
      const complianceProfile = R.path(
        ['partyComplianceProfile', 'complianceProfile'],
        party,
      );

      if (
        complianceProfile &&
        !localComplianceProfiles.find((el) => el._id === complianceProfile)
      ) {
        const res = await dispatch(fetchComplianceProfile(complianceProfile));
        const fetchedComplianceProfile = getGraphqlResponse(res);

        if (fetchedComplianceProfile.context !== 'global') {
          party.partyComplianceProfile.complianceProfile = null;
        }
      }

      setParties((prev) => [party, ...prev]);
    }
    setIsModalDataLoading(false);
  };

  const onChangeProfileAssociation = (updatedAssociation) => {
    setPartyProfileAssociation((partyProfileAssociation) => {
      return {
        ...partyProfileAssociation,
        ...updatedAssociation,
      };
    });
  };

  const deleteParty = (_id) => {
    setParties((parties) => parties.filter((party) => party._id !== _id));
  };

  const onToggleCheckbox = (updatedProfile, group, checkedKeys = []) => {
    const updatedGroup = R.propOr([], group, updatedProfile).map((el) =>
      checkedKeys.includes(el.attributeId)
        ? R.assoc('isActive', true, el)
        : R.assoc('isActive', false, el),
    );

    const index = R.findIndex(
      (profile) => profile._id === updatedProfile._id,
      localComplianceProfiles,
    );

    setLocalComplianceProfiles(
      R.update(
        index,
        R.assoc(group, updatedGroup, updatedProfile),
        localComplianceProfiles,
      ),
    );
  };

  const onSaveProject = () => {
    generalTabForm
      .validateFields()
      .then(async (values) => {
        setSubmitting(true);
        const updatedProfilesMappings = {};
        const updatingProfilesMappings = {};
        const fieldsToOmit = omittedFields.concat('customFields');

        // tracing compliance profiles
        const updatedComplianceProfilesNames = [];

        /**
         * projectId flag differentiates between edit and create mode
         */
        if (Boolean(projectId)) {
          /**
           * temporary implementation:
           * subscribe to projectUpdated socket in order to give a feedback when compliance sync process is completed.
           *
           * More explanation here: https://github.com/trustlayer/trustlayer-web/pull/1174
           *
           * @todo remove it as soon as the story https://app.shortcut.com/trustlayer/story/9536/visible-job-queue is done.
           */
          dispatch(openProjectUpdatesWSSubscription(projectId));
        }

        const processParty = async (party) => {
          const selectedProfileId = partyProfileAssociation[party._id];

          const selectedProfile = R.find(
            (profile) => profile._id === selectedProfileId,
            localComplianceProfiles,
          );

          if (!selectedProfile) {
            // This means that the party being processed has either no compliance profile or a custom one.
            return {
              partyId: party._id,
              complianceProfileId: null,
            };
          }

          const { name, rules, documentChecklists } = selectedProfile;

          const mapping = R.find(
            (el) => R.includes(selectedProfileId, R.values(el)),
            profilesMappings,
          );

          if (mapping) {
            console.log('Existing project-specific profile, updating...');
            if (!R.has(selectedProfileId, updatedProfilesMappings)) {
              // Since we update project profiles asyncronously, updatingProfilesMappings keeps track of the profiles that are already updating to avoid to start updating another one
              // If the profile is already updating, we wait for the result
              if (!R.has(selectedProfileId, updatingProfilesMappings)) {
                updatingProfilesMappings[selectedProfileId] = dispatch(
                  updateComplianceProfile({
                    _id: mapping.projectComplianceProfile,
                    name: name,
                    context: ComplianceProfileContext.Project,
                    contextId: projectId,
                    rules: rules.map((el) => R.omit(fieldsToOmit, el)),
                    documentChecklists: documentChecklists.map((el) => ({
                      ...R.omit(fieldsToOmit, el),
                      type: R.path(['type', '_id'], el),
                    })),
                  }),
                );

                await updatingProfilesMappings[selectedProfileId];
              } else {
                await updatingProfilesMappings[selectedProfileId];
              }

              updatedComplianceProfilesNames.push(name);

              // get result of promise updatingProfilesMappings[selectedProfileId]
              const result = await updatingProfilesMappings[selectedProfileId];
              updatedProfilesMappings[mapping.originalComplianceProfile] =
                getGraphqlPayload(result)?._id;
            }
          } else {
            console.log('New project-specific profile, creating...');
            // Since we want to create a new project profile for each unique profile, updatedProfilesMappings keeps track of the project profiles already created so that they are not re-created
            if (!R.has(selectedProfileId, updatedProfilesMappings)) {
              // Since we create project profiles asyncronously, updatingProfilesMappings keeps track of the profiles that are already creating to avoid to start creating another one
              // If the profile is already creating, we wait for the result
              if (!R.has(selectedProfileId, updatingProfilesMappings)) {
                updatingProfilesMappings[selectedProfileId] = dispatch(
                  createComplianceProfile({
                    ...R.omit(fieldsToOmit, selectedProfile),
                    name,
                    context: ComplianceProfileContext.Project,
                    contextId: projectId,
                    rules: rules.map((el) => R.omit(fieldsToOmit, el)),
                    documentChecklists: documentChecklists.map((el) => ({
                      ...R.omit(fieldsToOmit, el),
                      type: R.path(['type', '_id'], el),
                    })),
                  }),
                );

                await updatingProfilesMappings[selectedProfileId];
              } else {
                await updatingProfilesMappings[selectedProfileId];
              }

              // get result of promise updatingProfilesMappings[selectedProfileId]
              const result = await updatingProfilesMappings[selectedProfileId];
              updatedProfilesMappings[selectedProfileId] =
                getGraphqlPayload(result)?._id;
            }
          }

          return {
            partyId: party._id,
            complianceProfileId:
              updatedProfilesMappings[selectedProfileId] ||
              mapping.projectComplianceProfile,
          };
        };

        const partiesData = await Promise.all(parties.map(processParty));

        const normalizeCustomFieldValue = (el) => {
          switch (el.customField.type) {
            case CustomFieldType.Number:
              return isNaN(parseInt(el.value)) ? '' : el.value;
            default:
              return el.value ?? '';
          }
        };

        const [startDate, endDate] = values.dates || [null, null];

        const data = {
          name: values.name,
          description: getEditorHTMLContent(values.description),
          startDate: getUTCMomentDate(startDate),
          endDate: getUTCMomentDate(endDate),
          customFields: selectedCustomFields.map((el) => ({
            customField: el.customField._id,
            value: normalizeCustomFieldValue(el),
          })),
          partiesData,
          complianceProfiles: R.compose(
            R.map((id) => ({
              projectComplianceProfile: updatedProfilesMappings[id],
              originalComplianceProfile: id,
            })),
            R.keys,
          )(updatedProfilesMappings),
          files: uploadFiles,
        };

        let messageText = '';

        if (Boolean(projectId)) {
          const attachmentsToRemove = R.compose(
            R.pluck('_id'),
            R.reject((projectAttachment) =>
              R.find((el) => el._id === projectAttachment._id, attachments),
            ),
            R.propOr([], 'attachments'),
          )(project);
          const addedCustomFields = R.without(
            R.map((x) => x.customField._id, project?.customFields?.nodes ?? []),
            selectedCustomFields.map((el) => el.customField._id),
          );

          await dispatch(
            updateProject(
              R.compose(
                R.assoc('attachmentsToRemove', attachmentsToRemove),
                R.assoc('_id', projectId),
              )(data),
            ),
          );

          if (updatedComplianceProfilesNames.length) {
            trackEvent('User edited the compliance profile of a project');
          }
          if (addedCustomFields.length) {
            trackEvent('User added a custom field to a project');
          }

          if (onSuccess) {
            onSuccess();
          }
          messageText = 'Project saved, compliance synchronization in progress';
        } else {
          await dispatch(createProject(data));

          if (onSuccess) {
            onSuccess();
          }
          messageText = 'Project saved successfully';
        }

        setSubmitting(false);
        message.success(messageText);
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
        message.error('An error occurred while saving this project');
        setTab(TabKeys.General.key);
      });
  };

  return (
    <StyledModal
      maskClosable={false}
      title={
        projectId
          ? `Edit project ${R.propOr('', 'name', project)}`
          : 'New project'
      }
      open={visible}
      onCancel={onClose}
      width={1000}
      footer={[
        <StyledFooter key="footer-info">
          <ConnectivityBadge entity={project} showTitle={false} />
          {!isEmpty(R.propOr([], 'externalIds', project)) && (
            <Typography.Text>
              {'This project is synced with Procore'}
            </Typography.Text>
          )}
        </StyledFooter>,
        <Button
          key="close"
          onClick={onClose}
          data-cy="cancelProjectModalButton"
        >
          Cancel
        </Button>,
        <Button
          disabled={isModalDataLoading || loading || isFetchingProfiles}
          data-cy="saveProjectModalOkButton"
          key="submit"
          type="primary"
          loading={submitting}
          onClick={onSaveProject}
        >
          {projectId ? 'Update project' : 'Create project'}
        </Button>,
      ]}
    >
      {loading ? (
        <StyledLoading>
          <Spinner />
        </StyledLoading>
      ) : (
        <Tabs
          tabBarStyle={{ marginBottom: '30px' }}
          activeKey={tab}
          onChange={(key) => setTab(key)}
          animated={false}
        >
          <TabPane tab={TabKeys.General.name} key={TabKeys.General.key}>
            <GeneralTabForm
              form={generalTabForm}
              project={project}
              visible={visible}
            />
          </TabPane>
          <TabPane
            tab={
              <span data-cy="tabBtnCustomFields">
                {TabKeys.CustomFields.name}
              </span>
            }
            key={TabKeys.CustomFields.key}
          >
            <CustomFieldsTab
              customFields={customFields}
              selectedCustomFields={selectedCustomFields}
              onSetSelectedCustomFields={setSelectedCustomFields}
            />
          </TabPane>
          <TabPane
            tab={<span data-cy="tabBtnParties">{TabKeys.Parties.name}</span>}
            key={TabKeys.Parties.key}
          >
            <ProjectPartiesTabContainer
              parties={parties}
              onSelectParty={onSelectParty}
              partyProfileAssociation={partyProfileAssociation}
              onDeleteParty={deleteParty}
              onChangeProfileAssociation={onChangeProfileAssociation}
              complianceProfiles={localComplianceProfiles}
            />
          </TabPane>
          {isComplianceRequirementsAddOnEnabled && (
            <TabPane
              tab={
                <span data-cy="tabBtnComplianceProfiles">
                  {TabKeys.Requirements.name}
                </span>
              }
              key={TabKeys.Requirements.key}
            >
              {isFetchingProfiles ? (
                <Spinner />
              ) : (
                <ProjectRequirementsView
                  localComplianceProfiles={(
                    localComplianceProfiles ?? []
                  ).filter((el) =>
                    Object.values(partyProfileAssociation).includes(el._id),
                  )}
                  onCheckRequirement={onToggleCheckbox}
                  onCheckDocumentChecklist={onToggleCheckbox}
                />
              )}
            </TabPane>
          )}

          <TabPane
            tab={TabKeys.ProjectAttachments.name}
            key={TabKeys.ProjectAttachments.key}
          >
            <ProjectAttachmentsTabContainer
              uploadFiles={uploadFiles}
              onSetUploadFiles={setUploadFiles}
              attachments={attachments}
              onSetAttachments={setAttachments}
            />
          </TabPane>
        </Tabs>
      )}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }

  .ant-select {
    font-size: 13px;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
  }
`;

const StyledFooter = styled.div`
  margin-right: auto;
  opacity: 0.65;
  font-size: 12px;
`;

const StyledLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

export default SaveProjectModal;
